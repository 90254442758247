import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { BrowserChrome, BrowserEdge, BrowserFirefox, BrowserSafari } from "../../../../comman/icons";
import { useTableStyles, StyledTableCell } from "./styles"; 
import DeleteModal from "../Modal/DeleteModal";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ExecuteRun } from "../../../../redux/actions/AdvanceAutomation/JourneyAction";

const icons = {
    Chrome: <BrowserChrome size={20} />,
    Edge: <BrowserEdge size={20} />,
    Firefox: <BrowserFirefox size={20} />,
    Safari: <BrowserSafari size={20} />,
  };

  const useStyles = () => ({
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px', 
      flexDirection: 'row',
    }
  });  

export function CustomTable({ rows, handleEditRun }) {
  const classes = useTableStyles();
  const iconClasses = useStyles();
  const [isExecuting, setIsExecuting] = useState({});
  const [openDelModal, setOpenDelModal] = useState(false);
  const [selectedRun, setSelectedRun] = useState(null);
  const { userId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

const renderBrowserIcons = (browserString) => {
    const browsers = browserString.split(","); 
    return (
      <div className={iconClasses.iconContainer}>
        {browsers.map((browser) => (
          icons[browser.trim()] || null
        ))}
      </div>
    );
  };

  const handleDelete = (row) => {
    setOpenDelModal(true);
    setSelectedRun(row);
  };

  const handleEdit = (row) => {
    handleEditRun(row)
  };

  const handleExecute = async (row) => {
   setIsExecuting((prev) => ({
    ...prev,
    [row.Id]: true,
  }));

  setTimeout(() => {
    setIsExecuting((prev) => ({
      ...prev,
      [row.Id]: false,
    }));
  }, await dispatch(ExecuteRun(row.Id, userId))); 
  };

  return (
    <>
      <DeleteModal
        open={openDelModal}
        onClose={() => setOpenDelModal(false)}
        run={selectedRun}
      />
      <TableContainer className={classes.main} style={{ paddingRight: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Run Name</StyledTableCell>
              <StyledTableCell>Browser</StyledTableCell>
              <StyledTableCell>Build Version</StyledTableCell>
              <StyledTableCell>Release Version</StyledTableCell>
              <StyledTableCell>Workflow</StyledTableCell>
              <StyledTableCell>Passed</StyledTableCell>
              <StyledTableCell>Failed</StyledTableCell>
              <StyledTableCell>Last Executed On</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.Name} className={classes.tableRow} style={{ height: "10px" }}>
                <StyledTableCell component="th" scope="row">{row.Name}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{renderBrowserIcons(row.Browser)}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.BuildVersion}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.ReleaseVersion}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.TestCases}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.Passed}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.Failed}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.LastExecutedOn?row.LastExecutedOn:"NA"}</StyledTableCell>
                <StyledTableCell>
                    {/* {!isExecuting[row.Id] ? (
                      <Tooltip title="Execute">
                      <PlayCircleIcon
                        style={{ color: "rgb(101, 77, 247)", cursor: "pointer", marginRight: "10px", }}
                        onClick={() => handleExecute(row)}
                      />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Executing">
                      <CircularProgress style={{ color: "rgb(101, 77, 247)", cursor: "pointer", marginRight: "10px", }} size={25} />
                      </Tooltip>
                    )} */}
                  <Tooltip title="Edit">
                    <EditIcon
                      onClick={() => handleEdit(row)}
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        color: "rgb(101, 77, 247)",
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <DeleteIcon
                      onClick={() => handleDelete(row)}
                      style={{
                        cursor: "pointer",
                        color: "#F64E4E",
                      }}
                    />
                  </Tooltip>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
