import React, { useEffect, useState } from "react";
import { useStylesAdvan } from "./styles";
import { Grid, Card, Checkbox, FormControlLabel, Switch, Typography, IconButton } from "@material-ui/core";
import DetailsAccordion from "./DetailsAccordian"; 
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import SavDelModal from "../Modal/SavDelModal";

export default function Details({ journeyId }) {
  const classes = useStylesAdvan();
  const dispatch = useDispatch();
  const { journeyDetails } = useSelector((state) => state.journey);
  const [switchState, setSwitchState] = useState(journeyDetails.isBaseline);
  const [selectAll, setSelectAll] = useState(false);
  const [journeyData, setJourneyData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);  
  const [selectedItems, setSelectedItems] = useState(null); 
  const [isBaseline, setIsBaseline] = useState(false);
  const [isId, setIsId] = useState(null);
  const [selectedTestCases, setSelectedTestCases] = useState([]);

  useEffect(() => {
    setIsId(journeyDetails.id)
    setJourneyData(journeyDetails)
  })

  const handleToggleChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedItems(journeyData);
      setModalOpen(true);
      setIsBaseline(true);  // Set this to true when opening modal for baseline
    } else {
      setSwitchState(false);
      setIsBaseline(false);
    }
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedTestCases(journeyData.journeyResults.map(item => item.id));
    } else {
      setSelectedTestCases([]);
    }
  };

  const handleDeleteSelected = () => {
    if (selectedTestCases.length) {
      setIsBaseline(false);
      setSelectedItems(selectedTestCases.join(','));
      setModalOpen(true);
    } else {
      console.log("No items selected for deletion.");
    }
  };

  const handleTestCaseSelection = (testCaseId, isSelected) => {
    setSelectedTestCases(prevSelected => {
      let newSelected;
      if (isSelected) {
        newSelected = [...prevSelected, testCaseId];
      } else {
        newSelected = prevSelected.filter(id => id !== testCaseId);
      }
      // Update selectAll state based on whether all test cases are selected
      setSelectAll(newSelected.length === journeyData.journeyResults.length);
      return newSelected;
    });
  };

  const handleModalClose = (confirmed) => {
    if (confirmed) {
      // If the action is confirmed (saved), keep the switch on
      setSwitchState(true);
      setIsBaseline(true);
    } else {
      // If the action is canceled, turn the switch off
      setSwitchState(false);
      setIsBaseline(false);
    }
    setModalOpen(false);
  };

  return (
    <>
      <Grid container className={classes.header}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                style={{
                  cursor: "pointer",
                  color: "rgb(101, 77, 247)",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                checked={selectAll}
                onChange={handleSelectAllChange}
              />
            }
            label={
              <Typography
                style={{
                  color: "#646464",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Select All
              </Typography>
            }
          />
        </Grid>

        {/* Conditionally render the Delete button and label when selectAll is true */}
        {selectedTestCases.length > 0 && (
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <DeleteIcon
            style={{
              cursor: "pointer",
              color: "#F64E4E",
            }}
            onClick={handleDeleteSelected}
          />
          <Typography
            style={{
              color: "#646464",
              fontSize: "14px",
              fontWeight: "400",
              marginLeft: "5px",
              marginRight: "20px"
            }}
          >
            Delete Selected ({selectedTestCases.length})
          </Typography>
        </Grid>
      )}
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={switchState}
                onChange={handleToggleChange}
                color="primary"
                style={{
                  cursor: "pointer",
                  color: switchState ? "rgb(101, 77, 247)" : "#646464",
                }}
              />
            }
            label={
              <Typography
                style={{
                  color: "#646464",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Set as Baseline
              </Typography>
            }
          />
        </Grid>
      </Grid>
      <Grid>
        <Card
          style={{
            textAlign: "center",
            border: "2px solid #DADADA",
            margin: "0px 16px 10px 0px",
          }}
        >
          {journeyData ? (
             <DetailsAccordion
             baselineData={journeyDetails.baselineJourneyResults}
             journeyData={journeyDetails.journeyResults}
             journeyName={journeyDetails.name}
             selectAll={selectAll}
             onTestCaseSelection={handleTestCaseSelection}
             selectedTestCases={selectedTestCases}
           />
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Card>
        {/* SavDelModal */}
        {selectedItems && (
          <SavDelModal
          open={modalOpen}
          onClose={handleModalClose}
          item={selectedItems}
          indexJourneyId={isId}
          types={isBaseline ? "baseline" : "test case delete"}  // Control type based on action
          isBaseline={isBaseline}  // Pass baseline flag
        />
        )}
      </Grid>
    </>
  );
}