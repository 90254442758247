import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  Button,
  Breadcrumbs,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import { useStyles } from "./styles";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Donut from "./DonutChart";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BugReportIcon from "@mui/icons-material/BugReport";
import CustomVideoChell from "./modals/CustomVideoChell";
import { useNavigate } from "react-router-dom";

export default function TestSuiteResults() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState(null);
  const [playIconState, setPlayIconState] = useState("play");

  // Hard-coded data
  const testSuiteName = "Example Test Suite";
  const testRunName = "Run 001";
  const RootId = "123";

  const testCaseDetails = {
    PassedTestCases: 8,
    FailedTestCases: 2,
    TotalTestCases: 10,
    TestRunStartDate: "2023-06-15",
    TestRunStartTime: "09:00:00",
    TestRunEndDate: "2023-06-15",
    TestRunEndTime: "10:30:00",
    TesterName: "John Doe",
    TestEnvironment: "Production",
    TestUserName: "Rajat Gupta",
    ApplicationName: "GhostQA App",
    TestJourneyName: "Journey 1",
    TestBrowser: "Chrome",
    TestBuildVersion: "192.168",
    TestReleaseVersion: "V26.890",
    TestRunName: "TestRun-1",
    TestCaseDetailsList: [
      {
        TestCaseStatus: "Passed",
        TestCaseName: "Login Test",
        TestRunStartTime: "09:05:00",
      },
      {
        TestCaseStatus: "Failed",
        TestCaseName: "Search Functionality",
        TestRunStartTime: "09:15:00",
      },
      {
        TestCaseStatus: "Passed",
        TestCaseName: "User Profile Update",
        TestRunStartTime: "09:25:00",
      },
      {
        TestCaseStatus: "Passed",
        TestCaseName: "Logout Process",
        TestRunStartTime: "09:35:00",
      },
    ],
  };

  const testCaseStepsData = {
    "Login Test": {
      TestCaseName: "Login Test",
      TestCaseStartDate: "2023-06-15",
      TestCaseStartTime: "09:05:00",
      TestCaseEndDate: "2023-06-15",
      TestCaseEndTime: "09:10:00",
      TestCaseSteps: JSON.stringify([
        {
          Status: "Passed",
          TimeStamp: "09:05:10",
          Details: "Navigate to login page",
        },
        { Status: "Passed", TimeStamp: "09:05:20", Details: "Enter username" },
        { Status: "Passed", TimeStamp: "09:05:30", Details: "Enter password" },
        {
          Status: "Passed",
          TimeStamp: "09:05:40",
          Details: "Click login button",
        },
        {
          Status: "Passed",
          TimeStamp: "09:05:50",
          Details: "Verify successful login",
        },
      ]),
    },
    "Search Functionality": {
      TestCaseName: "Search Functionality",
      TestCaseStartDate: "2023-06-15",
      TestCaseStartTime: "09:15:00",
      TestCaseEndDate: "2023-06-15",
      TestCaseEndTime: "09:20:00",
      TestCaseSteps: JSON.stringify([
        {
          Status: "Passed",
          TimeStamp: "09:15:10",
          Details: "Navigate to search page",
        },
        {
          Status: "Passed",
          TimeStamp: "09:15:20",
          Details: "Enter search query",
        },
        {
          Status: "Passed",
          TimeStamp: "09:15:30",
          Details: "Click search button",
        },
        {
          Status: "Failed",
          TimeStamp: "09:15:40",
          Details: "Verify search results",
        },
      ]),
    },
    "User Profile Update": {
      TestCaseName: "User Profile Update",
      TestCaseStartDate: "2023-06-15",
      TestCaseStartTime: "09:25:00",
      TestCaseEndDate: "2023-06-15",
      TestCaseEndTime: "09:30:00",
      TestCaseSteps: JSON.stringify([
        {
          Status: "Passed",
          TimeStamp: "09:25:10",
          Details: "Navigate to user profile",
        },
        {
          Status: "Passed",
          TimeStamp: "09:25:20",
          Details: "Update user information",
        },
        { Status: "Passed", TimeStamp: "09:25:30", Details: "Save changes" },
        {
          Status: "Passed",
          TimeStamp: "09:25:40",
          Details: "Verify profile update",
        },
      ]),
    },
    "Logout Process": {
      TestCaseName: "Logout Process",
      TestCaseStartDate: "2023-06-15",
      TestCaseStartTime: "09:35:00",
      TestCaseEndDate: "2023-06-15",
      TestCaseEndTime: "09:40:00",
      TestCaseSteps: JSON.stringify([
        {
          Status: "Passed",
          TimeStamp: "09:35:10",
          Details: "Click logout button",
        },
        {
          Status: "Passed",
          TimeStamp: "09:35:20",
          Details: "Confirm logout action",
        },
        {
          Status: "Passed",
          TimeStamp: "09:35:30",
          Details: "Verify redirect to login page",
        },
      ]),
    },
  };

  const handleRowClick = (row) => {
    setActiveRow(row);
  };

  const handlePlayClick = () => {
    setPlayIconState("loading");
    setTimeout(() => {
      setPlayIconState("completed");
      setTimeout(() => {
        setPlayIconState('play');
      }, 2000);
    }, 5000);
  };

  const formatTimeDifference = (timeDifference) => {
    const hours = Math.floor(timeDifference / 3600000);
    const minutes = Math.floor((timeDifference % 3600000) / 60000);
    const seconds = Math.floor((timeDifference % 60000) / 1000);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const calculateDonutHeight = () => {
    const parentContainer = document.getElementById("donut-container");
    const parentContainerHeight = parentContainer
      ? parentContainer.clientHeight
      : window.innerHeight;
    const desiredPercentage = 38;
    const calculatedHeight = `${
      (parentContainerHeight * desiredPercentage) / 100
    }px`;

    return calculatedHeight;
  };

  const iconStyle = {
    fontSize: '2rem',  // Increase icon size
    cursor: 'pointer',
    marginRight: '10px', // Add some space between icon and Back button
    color: "rgb(101, 77, 247)", 
    cursor: "pointer",
  };

  return (
    <Grid className={classes.mainContainer}>
      {/* Header */}
      <Grid
        container
        style={{ marginBottom: "15px" }}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid>
          <Breadcrumbs separator="›" className={classes.breadCrumbHead}>
            <Box className={classes.breadCrumbStyle}>{testSuiteName}</Box>
            <Box fontSize="14px">{testRunName}</Box>
          </Breadcrumbs>
        </Grid>
        <Grid>
          <Stack direction="row" spacing={1} alignItems="center">
            {/* {playIconState === 'play' && (
              <PlayCircleIcon 
                onClick={handlePlayClick}
                style={iconStyle}
              />
            )}
            {playIconState === 'loading' && (
              <CircularProgress size={32} style={iconStyle} />
            )}
            {playIconState === 'completed' && (
              <CheckCircleIcon style={iconStyle} />
            )} */}
            <Button className={classes.backBtn} onClick={() => {navigate(-1)}}>
              <ArrowBackIcon fontSize="small" />
              Back
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {/* Main content */}
      <Grid container spacing={2}>
        {/* Left side content */}
        <Grid item xs={12} sm={7}>
          <Grid container spacing={2}>
            {/* Donut chart */}
            <Grid item xs={12} sm={6}>
              <Card
                style={{
                  background: "#f1f1f1",
                  height: "40vh",
                  padding: "10px 15px",
                }}
              >
                <Donut
                  series={[
                    testCaseDetails.PassedTestCases,
                    testCaseDetails.FailedTestCases,
                  ]}
                  labels={["Passed", "Failed"]}
                  height={calculateDonutHeight()}
                />
              </Card>
            </Grid>

            {/* Summary cards */}
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Card style={{ height: "19vh" }}>
                    <CardContent className={classes.hederStyle}>
                      <Typography style={{ fontSize: "14px" }}>
                        Tests
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Typography variant="body1" className={classes.tbodyFont}>
                        {testCaseDetails.PassedTestCases} Tests Passed
                      </Typography>
                      <Typography variant="body1" className={classes.tbodyFont}>
                        {testCaseDetails.FailedTestCases} Tests Failed
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card style={{ height: "19vh" }}>
                    <CardContent className={classes.hederStyle}>
                      <Typography style={{ fontSize: "14px" }}>
                        Start Date, Time
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Typography variant="body1" className={classes.tbodyFont}>
                        {`${testCaseDetails.TestRunStartDate} ${testCaseDetails.TestRunStartTime}`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card style={{ height: "19vh" }}>
                    <CardContent className={classes.hederStyle}>
                      <Typography style={{ fontSize: "14px" }}>
                        Tests
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Typography variant="body1" className={classes.tbodyFont}>
                        {testCaseDetails.TotalTestCases} Total Test Case
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#654DF7" }}
                        className={classes.tbodyFont}
                      >
                        {(
                          (testCaseDetails.PassedTestCases /
                            testCaseDetails.TotalTestCases) *
                          100
                        ).toFixed(2)}
                        %
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card style={{ height: "19vh" }}>
                    <CardContent className={classes.hederStyle}>
                      <Typography style={{ fontSize: "14px" }}>
                        Duration
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Typography variant="body1" className={classes.tbodyFont}>
                        {formatTimeDifference(
                          new Date(
                            `${testCaseDetails.TestRunEndDate}T${testCaseDetails.TestRunEndTime}`
                          ) -
                            new Date(
                              `${testCaseDetails.TestRunStartDate}T${testCaseDetails.TestRunStartTime}`
                            )
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Test case list */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                      <TableCell>Status</TableCell>
                      <TableCell>Test Case Name</TableCell>
                      <TableCell></TableCell>
                      <TableCell>Video</TableCell>
                      <TableCell>Start Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {testCaseDetails.TestCaseDetailsList.map((row, index) => (
                      <TableRow
                        key={index}
                        className={`${classes.hoverPointer} ${
                          row === activeRow ? classes.activeRow : ""
                        }`}
                        onClick={() => handleRowClick(row)}
                      >
                        <TableCell>
                          {row.TestCaseStatus === "Passed" ? (
                            <Icon
                              component={CheckCircleIcon}
                              style={{ color: "#198754" }}
                            />
                          ) : (
                            <Icon
                              component={CancelIcon}
                              style={{ color: "#dc3545" }}
                            />
                          )}
                        </TableCell>
                        <TableCell className={classes.tbodyFont}>
                          {row.TestCaseName}
                        </TableCell>
                        <TableCell>
                          {row.TestCaseStatus === "Failed" && (
                            <BugReportIcon style={{ color: "#dc3545" }} />
                          )}
                        </TableCell>
                        <TableCell>
                          {/* Implement video cell if needed */}
                          <CustomVideoChell row={row} />
                        </TableCell>
                        <TableCell className={classes.tbodyFont}>
                          {row.TestRunStartTime}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Right side content */}
        <Grid item xs={12} sm={5}>
          {/* Info card */}
          <Grid item xs={12}>
            <Card style={{ height: "40vh" }}>
              <CardContent className={classes.headrRightSite}>
                <Typography className={classes.theadFont}>Info...</Typography>
              </CardContent>
              <div style={{ height: "calc(40vh - 40px)", overflowY: "auto" }}>
                <Table>
                  <TableBody>
                    {[
                      {
                        name: "Environment",
                        value: testCaseDetails.TestEnvironment,
                      },
                      {
                        name: "Browser",
                        value: testCaseDetails.TestBrowser,
                      },
                      {
                        name: "Test User",
                        value: testCaseDetails.TestUserName,
                      },
                      {
                        name: "Build Version",
                        value: testCaseDetails.TestBuildVersion,
                      },
                      {
                        name: "Release Version",
                        value: testCaseDetails.TestReleaseVersion,
                      },
                      {
                        name: "Journey Name",
                        value: testCaseDetails.TestJourneyName,
                      },
                      {
                        name: "Run Name",
                        value: testCaseDetails.TestRunName,
                      },
                    ].map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.tbodyFont}>
                          {row.name}
                        </TableCell>
                        <TableCell className={classes.tbodyFont}>
                          {row.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Card>
          </Grid>

          {/* Test case steps */}
          {activeRow && (
            <Grid item xs={12}>
              <Card style={{ minHeight: "40vh", marginTop: "16px" }}>
                <CardContent className={classes.headrRightSite}>
                  <Typography>{activeRow.TestCaseName}</Typography>
                </CardContent>
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    padding: "10px 0 0 0",
                  }}
                >
                  <Box textAlign="center">
                    <Typography variant="subtitle1">Start DateTime</Typography>
                    <Chip
                      label={`${
                        testCaseStepsData[activeRow.TestCaseName]
                          .TestCaseStartDate
                      } ${
                        testCaseStepsData[activeRow.TestCaseName]
                          .TestCaseStartTime
                      }`}
                      color="primary"
                      variant="outlined"
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  </Box>
                  <Box textAlign="center">
                    <Typography variant="subtitle1">End DateTime</Typography>
                    <Chip
                      label={`${
                        testCaseStepsData[activeRow.TestCaseName]
                          .TestCaseEndDate
                      } ${
                        testCaseStepsData[activeRow.TestCaseName]
                          .TestCaseEndTime
                      }`}
                      color="secondary"
                      variant="outlined"
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  </Box>
                  <Box textAlign="center">
                    <Typography variant="subtitle1">Duration</Typography>
                    <Chip
                      label={formatTimeDifference(
                        new Date(
                          `${
                            testCaseStepsData[activeRow.TestCaseName]
                              .TestCaseEndDate
                          }T${
                            testCaseStepsData[activeRow.TestCaseName]
                              .TestCaseEndTime
                          }`
                        ) -
                          new Date(
                            `${
                              testCaseStepsData[activeRow.TestCaseName]
                                .TestCaseStartDate
                            }T${
                              testCaseStepsData[activeRow.TestCaseName]
                                .TestCaseStartTime
                            }`
                          )
                      )}
                      color="default"
                      variant="outlined"
                      style={{ marginBottom: 8 }}
                    />
                  </Box>
                </CardContent>
                <hr />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>Timestamp</TableCell>
                      <TableCell>Details</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {JSON.parse(
                      testCaseStepsData[activeRow.TestCaseName].TestCaseSteps
                    ).map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {row.Status === "Passed" ? (
                            <Icon
                              component={CheckCircleIcon}
                              style={{ color: "#198754" }}
                            />
                          ) : (
                            <Icon
                              component={CancelIcon}
                              style={{ color: "#dc3545" }}
                            />
                          )}
                        </TableCell>
                        <TableCell className={classes.tbodyFont}>
                          {row.TimeStamp}
                        </TableCell>
                        <TableCell style={{ overflowWrap: "anywhere" }}>
                          {row.Details}
                        </TableCell>
                        <TableCell>
                          {/* Implement screenshot cell if needed */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
