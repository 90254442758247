import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  saveBaselineJourney,
  DeleteJourneyTestCases,
} from "../../../../redux/actions/AdvanceAutomation/JourneyAction";

function SavDelModal({
  open,
  onClose,
  item,
  journeyId,
  indexJourneyId,
  types,
  isBaseline,
  saveBaseline,
}) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const { userId } = useSelector((state) => state.auth);

  useEffect(() => {
    if (item) {
      setName(
        types === "test case delete"
          ? typeof item === 'string'
            ? "selected test cases"
            : item.testCaseName || "this test case"
          : item.name || "this journey"
      );
    } else {
      console.warn("Item is undefined or null.");
    }
  }, [item, types]);

  // Handle test case deletion
  const handleDelete = async () => {
    try {
      if (types === "test case delete") {
        setLoading(true);
        const idsToDelete = typeof item === 'string' ? item : item.id.toString();
        await dispatch(DeleteJourneyTestCases(idsToDelete, indexJourneyId));
        onClose(true);
      }
    } catch (error) {
      console.error("Error handling delete:", error);
      onClose(false);
    } finally {
      setLoading(false);
    }
  }

  // Handle saving as baseline
  const handleSaveBaseline = async () => {
    try {
      setLoading(true);
      const payload = {
        userId: userId,
        rootId: item.rootId,
        isBaseline: true,
        clientReferenceId: item.clientReferenceId,
      };
      dispatch(saveBaselineJourney(payload, item.id));
      onClose(true);
    } catch (error) {
      console.error("Error saving baseline:", error);
      onClose(false);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <div>
      {open && (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {isBaseline
              ? "Save Baseline Confirmation"
              : "Delete Test Case Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {isBaseline
                ? `Are you sure you want to set "${name}" as the baseline?`
                : `Are you sure you want to delete "${name}" test case?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "20px" }}
          >
            <Button
              onClick={isBaseline ? handleSaveBaseline : handleDelete} // Choose the right action
              style={{
                marginRight: "10px",
                backgroundColor: "#654DF7",
                height: "30px",
                width: "100px",
                color: "white",
              }}
              disabled={loading}
            >
              {loading
                ? isBaseline
                  ? "Saving..."
                  : "Deleting..."
                : isBaseline
                ? "Save"
                : "Delete"}
            </Button>
            <Button
              onClick={handleCancel}
              color="primary"
              style={{
                backgroundColor: "#6c757d",
                width: "100px",
                height: "30px",
                color: "white",
              }}
              disabled={loading}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default SavDelModal;
