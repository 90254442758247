import React, { useEffect, useState } from "react";
import { Grid, Card, CircularProgress, Box } from "@material-ui/core";
import { useStylesTestCase } from "../styles";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteById } from "../../../../redux/actions/AdvanceAutomation/JourneyAction";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { JourneyTable } from "./JourneyTable";
import { CompleteIcon, LoaderIcon } from "../../../../comman/icons";
import { fetchJourneysByRootId, startJourney, clearJourneyList } from "../../../../redux/actions/AdvanceAutomation/JourneyAction";

export default function JourneyList({ depth }) {
  const classes = useStylesTestCase();
  const dispatch = useDispatch();
  const { selectedNodeId, selectedNode, siteData } = useSelector(
    (state) => state.journey
  );
  const { userId } = useSelector((state) => state.auth);
  const { journeys } = useSelector((state) => state.journey);
  
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [shouldFetchJourneys, setShouldFetchJourneys] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(clearJourneyList());
      await dispatch(fetchSiteById(selectedNodeId));
      setShouldFetchJourneys(true);
    };

    fetchData();
    setShouldFetchJourneys(false);
  }, [selectedNodeId, dispatch]);

  useEffect(() => {
    if (shouldFetchJourneys && siteData?.rootId) {
      const timer = setTimeout(() => {
        dispatch(fetchJourneysByRootId(siteData.id));
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [shouldFetchJourneys, siteData?.rootId, dispatch]);

  const handleStartJourney = async () => {
    setLoading(true);
    setCompleted(false);
    setShowTable(false);
  
    const postData = {
      crawlDetails: {
        start_url: siteData?.url, 
        max_depth: depth, 
      },
      userId: userId, 
      rootId: siteData?.id, 
    };
    // Post the data to the API
    try {
      await dispatch(startJourney(postData));
      setLoading(false);
      setCompleted(true);
      // After showing completed state for some time, reset
      setTimeout(() => {
        setCompleted(false);
        setShowTable(true);
      }, 5000);
    } catch (error) {
      console.error("Error starting journey:", error);
      setLoading(false);
    }
  };

  const testData = [
    { name: "URL", value: siteData?.url || "N/A" },
    { name: "Email", value: siteData?.email || "N/A" },
    { name: "Password", value: siteData?.password || "N/A" },
  ];

  return (
    <>
      {!loading && !completed && (
        <Grid
          container
          className={classes.Journeyheader}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={6} className={`${classes.Journeyheader}`}>
            <div className={classes.highlight}>
              {selectedNode?.name.length > 40
                ? selectedNode?.name.slice(0, 40) + "..."
                : selectedNode?.name}
            </div>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              sx={{
                backgroundColor: "rgb(101, 77, 247)",
                "&:hover": {
                  backgroundColor: "rgb(101, 77, 247) !important",
                  borderColor: "#654DF7",
                  color: "#fff",
                  "&:before": {
                    backgroundColor: "rgb(101, 77, 247) !important",
                    color: "#fff",
                  },
                },
                color: "#fff",
              }}
              onClick={handleStartJourney}
            >
              Start Journey
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          {loading && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {/* <LoaderIcon /> */}
              <Box
                component="img"
                src="/images/loader-main.gif" 
                alt="Loading GIF"
                sx={{ width: '200px', height: '200px' }} 
              />
              <Box
                mt={1}
                sx={{ color: "#646464", fontSize: "30px", fontWeight: "500" }}
              >
                Your Journey has started
              </Box>
              <Box
                mt={1}
                sx={{ color: "#646464", fontSize: "22px", fontWeight: "500" }}
              >
                Please Wait for few minutes
              </Box>
            </Box>
          )}
          {completed && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <CompleteIcon />
              <Box
                mt={1}
                sx={{ color: "#646464", fontSize: "30px", fontWeight: "500" }}
              >
                Journey is created
              </Box>
              <Box
                mt={1}
                sx={{ color: "#646464", fontSize: "22px", fontWeight: "500" }}
              >
                Now You can check Result
              </Box>
            </Box>
          )}
          {!loading && !completed && showTable && (
            <Grid item>
              {journeys.length !== 0 ? (
                <JourneyTable rows={journeys} rootId={siteData.rootId} />
              ) : (
                <>
                  <Grid item xs={6}>
                    <Card style={{ textAlign: "center" }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: "400" }}>
                              Name
                            </TableCell>
                            <TableCell style={{ fontWeight: "400" }}>
                              Value
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {testData.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.value}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
