import React, { useState, useEffect } from "react";
import {
  useStylesAdvan,
  StyledOutlinedInput,
  useStyles,
  StyledTableCell,
} from "./styles";
import {
  Grid,
  Card,
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
  FormControl,
  Box,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import {
  BrowserChrome,
  BrowserEdge,
  BrowserFirefox,
  BrowserSafari,
} from "../../../../../comman/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "react-select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import {
  GetAdTestUserList,
  GetReleaseVersion,
  GetBuildVersion,
} from "../../../../../redux/actions/settingAction";
import {
  GetEnvironmentAdAu,
  GetJourneyById,
  AddUpdateRun,
  DeleteJourneyTestCasesInRun,
} from "../../../../../redux/actions/AdvanceAutomation/JourneyAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function AddNewRun({
  onCancel,
  addOredit,
  runToEdit,
}) {
  const classes = useStylesAdvan();
  const classess = useStyles();
  const dispatch = useDispatch();
  const [runName, setRunName] = useState("");
  const [description, setDescription] = useState("");
  const [isEmailChecked, setisEmailChecked] = useState(false);
  const [emailOptionForMe, setEmailOptionForMe] = useState(false);
  const [emailOption, setEmailOption] = useState("");
  const [selectedIcon, setSelectedIcon] = useState([]);
  const [journeyData, setJourneyData] = useState([]);
  const [testUsersOptions, setTestUsersOptions] = useState([]);
  const [releaseVersionOptions, setReleaseVersionOptions] = useState([]);
  const [buildVersionOptions, setBuildVersionOptions] = useState([]);
  const [environmentOptions, setEnvironmentOptions] = useState([]);
  const [releaseVersion, setReleaseVersion] = useState(null);
  const [buildVersion, setBuildVersion] = useState(null);
  const [environment, setEnvironment] = useState(null);
  const [testUsers, setTestUsers] = useState(null);
  const [nerror, setNerror] = useState(false);
  const [derror, setDerror] = useState(false);
  const [terror, setTerror] = useState(null);
  const [rerror, setRerror] = useState(null);
  const [berror, setBerror] = useState(null);
  const [eerror, setEerror] = useState(null);
  const [brerror, setBrerror] = useState(null);
  const [aerror, setAerror] = useState(null);
  const [isExecute, setIsExecute] = useState(false);
  const [expandedJourney, setExpandedJourney] = useState("");
  const [itemsToDelete, setItemsToDelete] = useState(new Set());
  const [selectedTestCaseIds, setSelectedTestCaseIds] = useState(new Set());
  const { advanceUserList, releaseVersionList, buildVersionList } = useSelector(
    (state) => state.settings
  );
  const { environementList } = useSelector((state) => state.journey);
  const { journey_id, journeyDetails } = useSelector(
    (state) => state.journey
  );
  const { userId } = useSelector((state) => state.auth);
  const initialFormState = {
    Id: 0,
    Name: "",
    Description: "",
    TestUser: null,
    ReleaseVersion: null,
    BuildVersion: null,
    Environment: null,
    Browser: "",
    SendEmail: false,
    IsOnlyForMe: false,
    TestCasesIds: "",
  };
  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    dispatch(GetAdTestUserList());
    dispatch(GetReleaseVersion());
    dispatch(GetBuildVersion());
    dispatch(GetEnvironmentAdAu());
    dispatch(GetJourneyById(journey_id));
  }, [dispatch, journey_id]);

  useEffect(() => {
    if (addOredit === "Edit" && runToEdit) {
      setFormData(runToEdit);
      setRunName(runToEdit.Name);
      setDescription(runToEdit.Description);
      setisEmailChecked(runToEdit.SendEmail);
      setEmailOptionForMe(runToEdit.IsOnlyForMe);
      setEmailOption(
        runToEdit.IsOnlyForMe
          ? "only-for-me"
          : runToEdit.SendEmail
          ? "all-users"
          : ""
      );
      setSelectedIcon(runToEdit.Browser ? runToEdit.Browser.split(",") : []);
      const testCaseIds = runToEdit.TestCasesIds ? runToEdit.TestCasesIds.split(",").map(id => id.trim()) : [];
      setSelectedTestCaseIds(new Set(testCaseIds));
    } else {
      setFormData(initialFormState);
      setRunName("");
      setDescription("");
      setisEmailChecked(false);
      setEmailOptionForMe(false);
      setEmailOption("");
      setSelectedIcon([]);
      setSelectedTestCaseIds(new Set());
      setTestUsers(null);
      setReleaseVersion(null);
      setBuildVersion(null);
      setEnvironment(null);
    }
  }, [addOredit, runToEdit]);

  useEffect(() => {
    if (advanceUserList && advanceUserList.length > 0) {
      const optionsT = advanceUserList.map((user) => ({
        value: user.password,
        label: user.name,
      }));
      setTestUsersOptions(optionsT);
    }

    if (releaseVersionList && releaseVersionList.length > 0) {
      const optionsR = releaseVersionList.map((user) => ({
        value: user.version,
        label: user.version,
      }));
      setReleaseVersionOptions(optionsR);
    }

    if (buildVersionList && buildVersionList.length > 0) {
      const optionsB = buildVersionList.map((user) => ({
        value: user.version,
        label: user.version,
      }));
      setBuildVersionOptions(optionsB);
    }

    if (environementList && environementList.length > 0) {
      const optionsE = environementList.map((user) => ({
        value: user.baseUrl,
        label: user.name,
      }));
      setEnvironmentOptions(optionsE);
    }

    if (journeyDetails?.journeyResults) {
      setJourneyData(journeyDetails.journeyResults); 
    }
  }, [
    advanceUserList,
    releaseVersionList,
    buildVersionList,
    environementList,
    journeyDetails,
  ]);

  const CustomSwitch = styled(Switch)(({ theme, checked }) => ({
    "& .MuiSwitch-thumb": {
      backgroundColor: checked ? "#654DF7" : "#ffffff",
    },
    "& .MuiSwitch-track": {
      backgroundColor: checked ? "#654DF7" : "#cccccc",
    },
  }));

  const CustomRadio = styled(Radio)(({ theme }) => ({
    color: "#654DF7", 
    "&.Mui-checked": {
      color: "#654DF7", 
    },
    "& .MuiSvgIcon-root": {
      borderColor: "#654DF7", 
    },
  }));

  const handleCheckboxChange = (panelId) => (e) => {
    setSelectedTestCaseIds(prev => {
      const newSet = new Set(prev);
      if (e.target.checked) {
        newSet.add(panelId);
      } else {
        newSet.delete(panelId);
      }
      return newSet;
    });
  };

  const handleDeleteClick = (item) => {
    setItemsToDelete((prev) => new Set(prev.add(item.id))); 
  };

  const handleExpandJourney = (panel) => (e, isExpanded) => {
    setExpandedJourney(isExpanded ? panel : "");
  };

  const renderAccordion = (
    data,
    expanded,
    handleExpand,
    showCheckboxesAndDelete
  ) =>
    data.map((item, index) => {
      if (itemsToDelete.has(item.id)) {
        return null;
      }
  
      const testCaseSteps = JSON.parse(item.testCaseSteps || "{}");
      const steps = testCaseSteps.actions || [];
      const isChecked = selectedTestCaseIds.has(item.id.toString());
  
      return (
        <div style={{ margin: "5px 0 5px 5px" }} key={item.id}>
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleExpand(`panel${index}`)}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.accordionSummary}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                style={{ display: "flex", flexGrow: 1, alignItems: "center" }}
              >
                {showCheckboxesAndDelete && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        style={{
                          cursor: "pointer",
                          color: "rgb(101, 77, 247)",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        checked={isChecked}
                        onChange={handleCheckboxChange(item.id.toString())}
                      />
                    }
                    label={
                      <Typography className={classes.accordionLabel}>
                        {item.testCaseName}
                      </Typography>
                    }
                    className={classes.formControlLabel}
                  />
                )}
                {!showCheckboxesAndDelete && (
                  <Typography className={classes.accordionLabel}>
                    {item.testCaseName}
                  </Typography>
                )}
              </Box>
              {showCheckboxesAndDelete && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    minWidth: "100px",
                    alignItems: "center",
                  }}
                >
                  <DeleteIcon
                    style={{
                      cursor: "pointer",
                      color: "#F64E4E",
                    }}
                    onClick={() => handleDeleteClick(item)}
                  />
                </Box>
              )}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {steps.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Steps</StyledTableCell>
                        <StyledTableCell>Expected Results</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {steps.map((step, stepIndex) => (
                        <TableRow key={stepIndex} className={classes.tableRow}>
                          <StyledTableCell>{step.step}</StyledTableCell>
                          <StyledTableCell>{step.result}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No steps available
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    });

  const handleIconClick = (iconName) => {
    setSelectedIcon((prevSelectedIcon) =>
      prevSelectedIcon.includes(iconName)
        ? prevSelectedIcon.filter((name) => name !== iconName)
        : [...prevSelectedIcon, iconName]
    );
    if (selectedIcon.length > 0) {
      setBrerror(null);
    }
  };

  const icons = [
    { name: "Chrome", component: <BrowserChrome /> },
    { name: "Edge", component: <BrowserEdge /> },
    { name: "Firefox", component: <BrowserFirefox /> },
    { name: "Safari", component: <BrowserSafari /> },
  ];

  const handleToggleChange = () => {
    setFormData((prev) => ({
      ...prev,
      SendEmail: !prev.SendEmail,
      IsOnlyForMe: false, 
    }));
    setEmailOption(""); 
  };

  const handleRadioChange = (event) => {
    const newEmailOption = event.target.value;
    setEmailOption(newEmailOption);
    setFormData((prev) => ({
      ...prev,
      IsOnlyForMe: newEmailOption === "only-for-me",
    }));
  };

  const handleNameChange = (e) => {
    setFormData({ ...formData, Name: e.target.value });
    if (e.target.value.trim() !== "") {
      setNerror(false);
    }
  };

  const handleDescriptionChange = (e) => {
    setFormData({ ...formData, Description: e.target.value });
    if (e.target.value.trim() !== "") {
      setDerror(false);
    }
  };

  const handleTestUserChange = (selectedOption) => {
    setTestUsers(selectedOption);
    setFormData((prev) => ({ ...prev, TestUser: selectedOption?.value }));
    if (!selectedOption) {
      setTerror("Test User is required");
    } else {
      setTerror(null);
    }
  };

  const handleReleaseVersionChange = (selectedOption) => {
    setReleaseVersion(selectedOption);
    setFormData((prev) => ({ ...prev, ReleaseVersion: selectedOption?.value }));
    if (!selectedOption) {
      setRerror("Release Version is required");
    } else {
      setRerror(null);
    }
  };

  const handleBuildVersionChange = (selectedOption) => {
    setBuildVersion(selectedOption);
    setFormData((prev) => ({ ...prev, BuildVersion: selectedOption?.value }));
    if (!selectedOption) {
      setBerror("Build Version is required");
    } else {
      setBerror(null);
    }
  };

  const handleEnvironmentChange = (selectedOption) => {
    setEnvironment(selectedOption);
    setFormData((prev) => ({ ...prev, Environment: selectedOption?.value }));
    if (!selectedOption) {
      setEerror("Environment is required");
    } else {
      setEerror(null);
    }
  };

  const createPayload = () => {
    const testCasesIds = Array.from(selectedTestCaseIds).join(",");
    const browser = selectedIcon.join(",");
  
    let payload = {
      id: addOredit === "Edit" ? formData.Id : 0,
      journeyId: journey_id,
      userId: userId,
      name: formData.Name,
      description: formData.Description,
      testUser: formData.TestUser,
      releaseVersion: formData.ReleaseVersion,
      buildVersion: formData.BuildVersion,
      environment: formData.Environment,
      browser: browser,
      testCasesIds: testCasesIds,
      sendEmail: formData.SendEmail,
      isOnlyForMe: formData.IsOnlyForMe,
    };
  
    return payload;
  };

  const handleSubmit = async (execute = isExecute) => {

    if (itemsToDelete.size > 0) {
      const idsToDelete = Array.from(itemsToDelete).join(",");
      dispatch(DeleteJourneyTestCasesInRun(idsToDelete));
    }
  
    const updatedJourneyData = journeyData.filter(
      (item) => !itemsToDelete.has(item.id)
    );
  
    setJourneyData(updatedJourneyData);
  
    setItemsToDelete(new Set());
  
    let errorCount = 0;
  
    if (addOredit === "Edit") {
      if (formData.Name !== runToEdit.Name && formData.Name.trim() === "") {
        setNerror(true);
        errorCount++;
      }
      if (formData.Description !== runToEdit.Description && formData.Description.trim() === "") {
        setDerror(true);
        errorCount++;
      }
      if (formData.TestUser !== runToEdit.TestUser && formData.TestUser === null) {
        setTerror("Test User is required");
        errorCount++;
      }
      if (formData.ReleaseVersion !== runToEdit.ReleaseVersion && formData.ReleaseVersion === null) {
        setRerror("Release Version is required");
        errorCount++;
      }
      if (formData.BuildVersion !== runToEdit.BuildVersion && formData.BuildVersion === null) {
        setBerror("Build Version is required");
        errorCount++;
      }
      if (formData.Environment !== runToEdit.Environment && formData.Environment === null) {
        setEerror("Environment is required");
        errorCount++;
      }
      if (selectedIcon.length === 0 && runToEdit.Browser === "") {
        setBrerror("At Least One Browser Is Required");
        errorCount++;
      }
      if (selectedTestCaseIds.size === 0 && runToEdit.TestCasesIds === "") {
        setAerror("At Least One Test Case Is Required");
        errorCount++;
      }
    } else {
      if (formData.Name.trim() === "") {
        setNerror(true);
        errorCount++;
      }
      if (formData.Description.trim() === "") {
        setDerror(true);
        errorCount++;
      }
      if (formData.TestUser === null) {
        setTerror("Test User is required");
        errorCount++;
      }
      if (formData.ReleaseVersion === null) {
        setRerror("Release Version is required");
        errorCount++;
      }
      if (formData.BuildVersion === null) {
        setBerror("Build Version is required");
        errorCount++;
      }
      if (formData.Environment === null) {
        setEerror("Environment is required");
        errorCount++;
      }
      if (selectedIcon.length === 0) {
        setBrerror("At Least One Browser Is Required");
        errorCount++;
      }
      if (selectedTestCaseIds.size === 0) {
        setAerror("At Least One Test Case Is Required");
        errorCount++;
      }
    }
  
    if (errorCount > 1) {
      toast.error("Some fields are empty");
    } else if (errorCount === 1) {
      if (formData.Name.trim() === "") {
        toast.error("Name is empty");
      }
      if (formData.Description.trim() === "") {
        toast.error("Description is empty");
      }
      if (formData.TestUser === null) {
        toast.error("Select Test User");
      }
      if (formData.ReleaseVersion === null) {
        toast.error("Select Release Version");
      }
      if (formData.BuildVersion === null) {
        toast.error("Select Build Version");
      }
      if (formData.Environment === null) {
        toast.error("Select Environment");
      }
      if (selectedIcon.length === 0) {
        toast.error("Select Browser");
      }
      if (selectedTestCaseIds.size === 0) {
        toast.error("Select Test Case");
      }
    } else {
      const payload = createPayload();
      if(execute == true) {
        let execute = true;
        dispatch(AddUpdateRun(payload, journey_id, execute, userId));
      } else {
        let execute = false;
        dispatch(AddUpdateRun(payload, journey_id, execute, userId));
      }
      onCancel();
    }
  };

  const handleSaveAndExecute = () => {
    // setIsExecute(true);
    handleSubmit(true);
  };

  return (
    <>
      <Grid
        container
        className={classes.header}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={6}>
          <Typography variant="h5" className={classes.highlight}>
            {addOredit=="Add" ? "Add New Run" : "Edit Run"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.content}>
        {/* Inner Card */}
        <Card className={classes.innerCard}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container className={classes.body} spacing={1}>
                {/* Row 1: Name Input Field, Description */}
                <Grid item xs={3}>
                  <div
                    className={classes.input}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography variant="subtitle1">Name</Typography>
                    <StyledOutlinedInput
                      type="text"
                      placeholder="Enter Run Name"
                      value={formData.Name}
                      onChange={handleNameChange}
                      error={nerror}
                    />
                    {nerror && (
                      <Typography variant="caption" color="error">
                        Name is required
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div
                    className={classes.input}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography variant="subtitle1">Description</Typography>
                    <StyledOutlinedInput
                      type="text"
                      placeholder="Enter Description"
                      value={formData.Description}
                      onChange={handleDescriptionChange}
                      style={{ width: "calc(100vw-50px)" }}
                      error={derror}
                    />
                    {derror && (
                      <Typography variant="caption" color="error">
                        Discription is required
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.body} spacing={1}>
                {/* Row 2: test user, Release Version, Build Version */}
                <Grid item xs={3}>
                  <div
                    className={classes.input}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography variant="subtitle1">Test User</Typography>
                    <Select
                      isClearable={true}
                      placeholder="Select Test User"
                      options={testUsersOptions}
                      value={testUsersOptions.find(
                        (option) => option.value === formData.TestUser
                      )}
                      onChange={handleTestUserChange}
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          backgroundColor: "rgb(242, 242, 242)",
                          width: "calc(100vw-900px)",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          backgroundColor: "rgb(242, 242, 242)",
                          "&:hover": {
                            borderColor: "#654DF7",
                          },
                          borderColor: state.isFocused
                            ? "#654DF7"
                            : "rgb(242, 242, 242)",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#654DF7"
                            : "transparent",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          cursor: "pointer",
                          ":hover": {
                            color: "#654DF7",
                          },
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          cursor: "pointer",
                          ":hover": {
                            color: "#654DF7",
                          },
                        }),
                      }}
                      menuPosition={"fixed"}
                    />
                    {terror && (
                      <Typography variant="caption" color="error">
                        {terror}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    className={classes.input}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography variant="subtitle1">Release Version</Typography>
                    <Select
                      isClearable={true}
                      placeholder="Select Release Version"
                      options={releaseVersionOptions}
                      value={releaseVersionOptions.find(
                        (option) => option.value === formData.ReleaseVersion
                      )}
                      onChange={handleReleaseVersionChange}
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          backgroundColor: "rgb(242, 242, 242)",
                          width: "calc(100vw-900px)",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          backgroundColor: "rgb(242, 242, 242)",
                          "&:hover": {
                            borderColor: "#654DF7",
                          },
                          borderColor: state.isFocused
                            ? "#654DF7"
                            : "rgb(242, 242, 242)",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#654DF7"
                            : "transparent",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          cursor: "pointer",
                          ":hover": {
                            color: "#654DF7",
                          },
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          cursor: "pointer",
                          ":hover": {
                            color: "#654DF7",
                          },
                        }),
                      }}
                      menuPosition={"fixed"}
                    />
                    {rerror && (
                      <Typography variant="caption" color="error">
                        {rerror}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    className={classes.input}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography variant="subtitle1">Build Version</Typography>
                    <Select
                      isClearable={true}
                      placeholder="Select Build Version"
                      options={buildVersionOptions}
                      value={buildVersionOptions.find(
                        (option) => option.value === formData.BuildVersion
                      )}
                      onChange={handleBuildVersionChange}
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          backgroundColor: "rgb(242, 242, 242)",
                          width: "calc(100vw-900px)",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          backgroundColor: "rgb(242, 242, 242)",
                          "&:hover": {
                            borderColor: "#654DF7",
                          },
                          borderColor: state.isFocused
                            ? "#654DF7"
                            : "rgb(242, 242, 242)",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#654DF7"
                            : "transparent",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          cursor: "pointer",
                          ":hover": {
                            color: "#654DF7",
                          },
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          cursor: "pointer",
                          ":hover": {
                            color: "#654DF7",
                          },
                        }),
                      }}
                      menuPosition={"fixed"}
                    />
                    {berror && (
                      <Typography variant="caption" color="error">
                        {berror}
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.body} spacing={1}>
                {/* Row 3: Environment */}
                <Grid item xs={3}>
                  <div
                    className={classes.input}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography variant="subtitle1">Environment</Typography>
                    <Select
                      isClearable={true}
                      placeholder="Select Environment"
                      options={environmentOptions}
                      value={environmentOptions.find(
                        (option) => option.value === formData.Environment
                      )}
                      onChange={handleEnvironmentChange}
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          backgroundColor: "rgb(242, 242, 242)",
                          width: "calc(100vw-900px)",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          backgroundColor: "rgb(242, 242, 242)",
                          "&:hover": {
                            borderColor: "#654DF7",
                          },
                          borderColor: state.isFocused
                            ? "#654DF7"
                            : "rgb(242, 242, 242)",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#654DF7"
                            : "transparent",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          cursor: "pointer",
                          ":hover": {
                            color: "#654DF7",
                          },
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          cursor: "pointer",
                          ":hover": {
                            color: "#654DF7",
                          },
                        }),
                      }}
                      menuPosition={"fixed"}
                    />
                    {eerror && (
                      <Typography variant="caption" color="error">
                        {eerror}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    className={classes.input}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Grid container spacing={2} sx={{ width: "100%" }}>
                      <Grid item className={classess.container}>
                        <Box className={classess.togglebox}>
                          <Typography className={classess.customFontSize}>
                            Email Result
                          </Typography>
                        </Box>
                        <Box className={classess.togglebox}>
                          <CustomSwitch
                            checked={formData.SendEmail}
                            onChange={handleToggleChange}
                          />
                        </Box>
                        <Box className={classess.optionsContainer}>
                          {formData.SendEmail && (
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="options"
                                name="options"
                                value={emailOption}
                                onChange={handleRadioChange}
                                row
                                style={{
                                  marginTop: "30px",
                                  marginLeft: "10px",
                                }}
                              >
                                <FormControlLabel
                                  value="only-for-me"
                                  control={<CustomRadio />}
                                  label={
                                    <Typography
                                      variant="body1"
                                      className={classess.radioButtonLabel}
                                    >
                                      Me
                                    </Typography>
                                  }
                                  className={classess.radioLabel}
                                />
                                <FormControlLabel
                                  value="all-users"
                                  control={<CustomRadio />}
                                  label={
                                    <Typography
                                      variant="body1"
                                      className={classess.radioButtonLabel}
                                    >
                                      All
                                    </Typography>
                                  }
                                  className={classess.radioLabel}
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.body} spacing={1}>
                {/* Row 4: Browser */}
                <Grid item xs={12}>
                  <div
                    className={classes.input}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography variant="subtitle1">Browser</Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    className={classes.iconsContainer}
                    style={{ display: "flex", marginTop: "8px" }}
                  >
                    {icons.map((icon, index) => (
                      <Button
                        key={icon.name}
                        onClick={() => handleIconClick(icon.name)}
                        style={{
                          position: "relative",
                          border: selectedIcon.includes(icon.name)
                            ? "2px solid green"
                            : "2px solid transparent",
                          borderRadius: "8px",
                          padding: "8px",
                          minWidth: "auto", 
                          marginLeft: index !== 0 ? "10px" : "0px", 
                        }}
                      >
                        {icon.component}
                        {selectedIcon.includes(icon.name) && (
                          <CheckIcon
                            style={{
                              position: "absolute",
                              bottom: "-7px",
                              right: "-10px",
                              fontSize: "large",
                              color: "White",
                              backgroundColor: "green",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </Button>
                    ))}
                    {brerror && (
                      <Typography variant="caption" color="error">
                        {brerror}
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.lasttwomargin}>
              <Grid container className={classes.body} spacing={1}>
                {/* Row 4: test case accordion */}
                <Grid item xs={12}>
                  <div
                    className={classes.input}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {renderAccordion(
                      journeyData,
                      expandedJourney,
                      handleExpandJourney,
                      true
                    )}
                    {aerror && (
                      <Typography variant="caption" color="error">
                        {aerror}
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.body} spacing={1}>
                {/* Row 4: Save And Cancel */}
                <Grid item xs={12}>
                  <div
                    className={classes.input}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "8px",
                        mt: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#654DF7",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#5a3cba",
                          },
                        }}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#654DF7",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#5a3cba",
                          },
                        }}
                        onClick={handleSaveAndExecute}
                      >
                        Save & Execute
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          color: "#654DF7",
                          borderColor: "#654DF7",
                          "&:hover": {
                            borderColor: "#5a3cba",
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}
