import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  OutlinedInput,
  FormControl,
  Button,
  Typography,
  FormControlLabel,
  Grid,
  Box,
  Card,
  CircularProgress,
} from "@mui/material";
import useStyles, { StyledTypography } from "./styles";
import clsx from "clsx";
import Select from "react-select";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { TestCaseTable } from "./TestCaseTable";
import SearchField from "../../comman/SearchField";
import { useDispatch } from "react-redux";
import {
  GetApplication,
  GetEnvironment,
  GetTestCases,
  AddUpdateTestSuites,
  Getsuitebyname,
  GetTestUser,
  GetTestCaseByApplicationId,
  setSelectedTab,
  GET_TESTCASE_BY_APPLICATION,
} from "../../redux/actions/seleniumAction";
import { useParams } from "react-router-dom";
import { GetTestUserList } from "../../redux/actions/settingAction";
import { Switch } from "@mui/material";
import { Handle_sequentail_parralel_execution } from "../../redux/actions/seleniumAction";
import { ExecuteTestCasesByTestSuite } from "../../redux/actions/seleniumAction";

export default function EditTestSuite() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const navigate = useNavigate();
  const { suiteName, id, rootid } = useParams();
  const [selectedSuiteValue, setSelectedSuiteValue] = useState("custom-Suites");
  const [selectedRecepentValue, setSelectedRecepentValue] = useState("");
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [testCases, setTestCases] = useState([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [selectedTestUser, setSelectedTestUser] = useState(null);
  const [isSecondGridVisible, setIsSecondGridVisible] = useState("");
  const [executionMode, setselectedExecutionMode] = useState(false);
  const [batchSize, setbatchSize] = useState(null);
  const [nodeId, setNodeId] = useState(null);
  const storedEmail = localStorage.getItem("userEmail");
  const { userId } = useSelector((state) => state.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");

  const [description, setDescription] = useState("");
  const [Error, setError] = useState({
    name: "",
    application: "",
    environment: "",
    test_user: "",
    browser: "",
    description: "",
    batchSize: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  // const [openLoadingModal, setopenLoadingModal] = useState(false);
  const {
    applicationList,
    environementList,
    suiteToEdit,
    executingSuite,
    testCaseListByApplication,
  } = useSelector((state) => state.selenium);
  const { selectedNodeId, expanded } = useSelector((state) => state.localTest);

  const { testUserList } = useSelector((state) => state.settings);
  const [isExecuting, setisExecuting] = useState(false);
  const [nameLengthError, setnameLengthError] = useState(false);

  useEffect(() => {
    if (suiteToEdit?.Application?.ApplicationId !== undefined) {
      dispatch(
        GetTestCaseByApplicationId(suiteToEdit.Application.ApplicationId)
      );
    }
  }, [suiteToEdit]);

  useEffect(() => {
    if (selectedApplication) {
      dispatch(GetTestCaseByApplicationId(selectedApplication?.ApplicationId));
    } else {
      setTestCases([]);
    }
  }, [selectedApplication, dispatch]);

  useEffect(() => {
    if (selectedApplication) {
      const filteredTestCases = testCaseListByApplication
        .map((data) => ({
          ...data,
          isChecked: selectedRows.some(
            (selectedRow) => selectedRow.TestCaseName === data.TestCaseName
          ),
        }))
        .filter((data) =>
          data?.TestCaseName?.toLowerCase()?.includes(searchTerm.toLowerCase())
        );

      setTestCases(filteredTestCases);
    } else {
      setTestCases([]);
    }
  }, [testCaseListByApplication, searchTerm, selectedRows]);

  useEffect(() => {
    // Extract the number from the URL
    const url = window.location.pathname;
    const parts = url.split("/");
    const id = parts[parts.length - 1];

    // Check if the extracted part is a number and set it as nodeId
    if (!isNaN(id)) {
      setNodeId(Number(id));
    }
  }, []);

  const numericOptions = [
    { label: "2", value: 2 },
    { label: "3", value: 3 },
  ];

  useEffect(() => {
    setSelectAll(false);
    setSelectedRows([]);
  }, [selectedApplication]);

  useEffect(() => {
    dispatch(GetApplication());
    dispatch(GetEnvironment());
    dispatch(GetTestUserList());
    // dispatch(GetTestCases());
    if (!suiteToEdit) {
      dispatch(Getsuitebyname(suiteName, rootid));
    }
    setName(suiteToEdit?.TestSuiteName);
    setSelectedApplication(() => {
      return applicationList?.find(
        (app) => app.ApplicationId == suiteToEdit?.Application?.ApplicationId
      );
      // return x;
    });
    //work on all-user
    setSelectedRecepentValue(
      suiteToEdit?.IsOnlyForMe ? "only-for-me" : "all-users"
    );
    setselectedExecutionMode(
      suiteToEdit?.IsExecutionOrderParallel ? "Parallel" : "Sequential"
    );
    setbatchSize(suiteToEdit?.BatchSize);

    setIsSecondGridVisible(suiteToEdit?.SendEmail);
    setSelectedEnvironment(() => {
      return environementList?.find(
        (env) => env.EnvironmentId === suiteToEdit?.Environment?.EnvironmentId
      );
    });

    setSelectedTestUser(() => {
      return testUserList?.find(
        (env) => env.UserId === suiteToEdit?.TestUser?.TestUserId
      );
    });
    setDescription(suiteToEdit?.Description);
  }, [dispatch, suiteToEdit]);

  // useEffect(() => {
  //   if (suiteToEdit && testCaseListByApplication.length > 0) {
  //     setSelectedRows(() => {
  //       const selectedTestCasesArray = suiteToEdit.SelectedTestCases
  //         ? suiteToEdit.SelectedTestCases.split(",")
  //         : [];
  //       return testCaseListByApplication.filter((test) =>
  //         selectedTestCasesArray.some(
  //           (selectedTestCase) => selectedTestCase.trim() === test.TestCaseName
  //         )
  //       );
  //     });
  //   }
  // }, [suiteToEdit, testCaseListByApplication]);

  useEffect(() => {
    if (suiteToEdit && testCaseListByApplication.length > 0) {
      const selectedTestCasesArray = suiteToEdit.SelectedTestCases
        ? suiteToEdit.SelectedTestCases.split(",")
        : [];
      const selectedRows = testCaseListByApplication.filter((test) =>
        selectedTestCasesArray.some(
          (selectedTestCase) => selectedTestCase.trim() === test.TestCaseName
        )
      );
      setSelectedRows(selectedRows);

      // Check if all tests are selected
      if (selectedRows.length === testCaseListByApplication.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  }, [suiteToEdit, testCaseListByApplication]);

  const handleRadioChange = (event) => {
    setSelectedSuiteValue(event.target.value);
  };

  const handleRadioChangeRecepent = (event) => {
    setSelectedRecepentValue(event.target.value);
  };

  const handleRadioChangeRecepent_executionmode = (event) => {
    setselectedExecutionMode(event.target.value);
  };

  const handleToggleChange = (event) => {
    setIsSecondGridVisible(event.target.checked);
  };

  const handleNameChange = (e) => {
    // setName(e.target.value);
    const inputValue = e.target.value;
    if (inputValue.length <= 50) {
      setName(inputValue);
      setnameLengthError(false);
      setError((prev) => ({ ...prev, name: "" }));
    } else {
      setnameLengthError(true);
    }
  };
  const handleApplication = (env) => {
    const app = env
      ? {
          ApplicationId: env.ApplicationId,
          ApplicationName: env.ApplicationName,
        }
      : null;
    setSelectedApplication(app);
  };

  const getTestcaseNameOnly = () => {
    let testCaseArrName = [];
    selectedRows.map((testCase) => testCaseArrName.push(testCase.TestCaseName));
    // .join(",");
    return testCaseArrName;
  };

  const handleLoading = (status) => {
    // setopenLoadingModal(false)
    setisExecuting(false);
    if (status === "pass") navigate("/ghost-ready");
  };
  const handleSubmit = (action) => {
    if (isSubmitting) return;
    const testCaseNames = getTestcaseNameOnly();
    let payload = {
      TestSuiteName: name,
      Description: description,
      TestSuiteId: suiteToEdit?.TestSuiteId,
      TestSuiteType: selectedSuiteValue,
      TestUserId: selectedTestUser?.UserId,
      ApplicationId: selectedApplication?.ApplicationId,
      sendEmail: isSecondGridVisible,
      isOnlyForMe: selectedRecepentValue === "only-for-me" ? true : false,
      EnvironmentId: selectedEnvironment?.EnvironmentId,
      isExecutionOrderParallel: executionMode == "Parallel" ? true : false,
      batchSize: batchSize,

      // browser: selectedBrowser.BrowserId,
      rootId: parseInt(rootid),
      SelectedTestCases: testCaseNames,
      AllTestCases: [
        {
          disabled: true,
          group: {
            disabled: true,
            name: "string",
          },
          selected: true,
          text: "string",
          value: "string",
        },
      ],
    };
    let paylaod_execute = {
      testSuiteName: name,
      userId: userId,
      rootId: nodeId,
      userEmail: storedEmail,
      batchSize: batchSize,
    };
    let error = {};
    if (!name.trim()) {
      error.name = "Name is required";
    }
    if (!selectedEnvironment) {
      error.environment = "Environment is required";
    }
    if (!selectedTestUser) {
      error.test_user = "Test User is required";
    }
    if (!description.trim()) {
      error.description = "Description is required";
    }
    if (testCaseNames.length === 0) {
      error.testCaseError = "1px solid red";
      error.testCaseErrorText = "Select atleast one test case";
    }
    if (executionMode === "Parallel" && !batchSize) {
      error.batchSize = "Parallel Thread field is required";
    }

    // Update error state
    setError(error);

    // Check if there are any errors
    if (Object.keys(error).length === 0) {
      // Proceed with form submission
      if (action === "SaveAndExecute") {
        // setopenLoadingModal(true)
        setisExecuting(true);
      }
      dispatch(
        AddUpdateTestSuites(payload, action, handleLoading, setIsSubmitting)
      );
    } else console.log("handleSubmit error", error, payload);
  };

  const handleCheckboxChange = (event, row) => {
    const checked = event.target.checked;
    const checkedRows = checked
      ? [...selectedRows, row]
      : selectedRows.filter(
          (selectedRow) => selectedRow.TestCaseName !== row.TestCaseName
        );
    setSelectedRows(checkedRows);
    if (checkedRows.length === testCaseListByApplication.length)
      setSelectAll(true);
    else setSelectAll(false);

    // Clear the error if at least one test case is selected
    if (checkedRows.length > 0) {
      setError((prevError) => ({
        ...prevError,
        testCaseError: "",
        testCaseErrorText: "",
      }));
    }
  };

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    const newSelectedRows = checked ? testCaseListByApplication : [];
    setSelectedRows(newSelectedRows);

    // Clear the error if at least one test case is selected
    if (newSelectedRows.length > 0) {
      setError((prevError) => ({
        ...prevError,
        testCaseError: "",
        testCaseErrorText: "",
      }));
    }
  };

  const handleGetTestCase = (application) => {
    dispatch(GetTestCaseByApplicationId(application?.ApplicationId));
  };

  const handleApplicationChange = (application) => {
    setSelectedApplication(application);
    if (application) {
      // Fetch test cases for the selected application
      dispatch(GetTestCaseByApplicationId(application?.ApplicationId));
    } else {
      // Clear the test cases if no application is selected
      setTestCases([]);
    }
  };

  const filteredTestCaseData = testCaseListByApplication
    .map((data) => ({
      ...data,
      isChecked:
        selectedRows.find(
          (selectedRow) => selectedRow.TestCaseName === data.TestCaseName
        ) !== undefined,
    }))
    .filter((data) =>
      data?.TestCaseName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  // const selectStyle = {
  //   container: (provided) => ({
  //     ...provided,
  //     backgroundColor: "rgb(242, 242, 242)",
  //   }),
  //   control: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: "rgb(242, 242, 242)",
  //     "&:hover": {
  //       borderColor: "#654DF7",
  //     },
  //     borderColor: Error.environment
  //       ? "red"
  //       : state.isFocused
  //       ? "#654DF7"
  //       : "rgb(242, 242, 242)",
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#654DF7" : "transparent",
  //   }),
  //   clearIndicator: (provided) => ({
  //     ...provided,
  //     cursor: "pointer",
  //     ":hover": {
  //       color: "#654DF7", // Change the color on hover if desired
  //     },
  //   }),
  //   dropdownIndicator: (provided, state) => ({
  //     ...provided,
  //     cursor: "pointer",
  //     transform: state.selectProps.menuIsOpen
  //       ? "rotate(180deg)"
  //       : "rotate(0deg)",
  //     transition: "transform 0.2s ease",
  //     ":hover": {
  //       color: "#654DF7", // Change the color on hover if desired
  //     },
  //   }),
  //   indicatorSeparator: (provided) => ({
  //     ...provided,
  //     display: "none", // Hide the separator if desired
  //   }),
  // };

  const getSelectStyle = (hasError) => ({
    container: (provided) => ({
      ...provided,
      backgroundColor: "rgb(242, 242, 242)",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(242, 242, 242)",
      "&:hover": {
        borderColor: hasError ? "red" : "#654DF7",
      },
      borderColor: hasError
        ? "red"
        : state.isFocused
        ? "#654DF7" // Focus color
        : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #654DF7" : provided.boxShadow,
      "&:hover": {
        borderColor: hasError ? "red" : "#654DF7", // Hover color
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#654DF7" : "transparent",
      zIndex: 9999,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      cursor: "pointer",
      ":hover": {
        color: "#654DF7",
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      transform: state.selectProps.menuIsOpen
        ? "rotate(180deg)"
        : "rotate(0deg)",
      transition: "transform 0.2s ease",
      ":hover": {
        color: "#654DF7",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  });

  const handleCancel = () => {
    navigate(-1);
    dispatch(setSelectedTab("custom"));
    dispatch({
      type: GET_TESTCASE_BY_APPLICATION,
      payload: [],
    });
  };

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress sx={{ color: "#654DF7" }} />
        </Box>
      }
    >
      <div className={classes.main}>
        <Grid container>
          {/* First Section */}
          <Grid item xs={12} sm={4}>
            {/* Left Section Part 1 */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <Box className={classes.sideBar}>
                    <b>New Suite</b>
                  </Box>
                  <div
                    style={{
                      padding: "0px 12px",
                    }}
                  >
                    <Grid container className={classes.body}>
                      {/* Row 1: Name Input Field */}
                      <Grid item>
                        <div className={classes.input}>
                          <Typography
                            variant="subtitle1"
                            className={clsx(classes.customFontSize)}
                          >
                            Name:
                          </Typography>
                          <FormControl
                            className={clsx(classes.textField)}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                  borderColor: "#654DF7",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#654DF7",
                                },
                                "& fieldset": {
                                  borderColor: "transparent ",
                                },
                              },
                              height: "40px",
                            }}
                          >
                            <OutlinedInput
                              id="outlined-adornment-name"
                              type="text"
                              placeholder="Enter your test suite name"
                              value={name}
                              error={Error.name ? true : false}
                              disabled={false}
                              onChange={handleNameChange}
                              className={clsx(
                                classes.customheight,
                                classes.customFontSize,
                                classes.customBackgroung
                              )}
                            />
                          </FormControl>
                          {nameLengthError && (
                            <StyledTypography>
                              Suite name cannot have more than 50 char*
                            </StyledTypography>
                          )}
                          {Error.name && (
                            <StyledTypography>{Error.name}</StyledTypography>
                          )}
                        </div>
                      </Grid>

                      {/* Row 2: Test Description Input Field */}
                      <Grid item mb={1}>
                        <div className={classes.input}>
                          <Typography
                            variant="subtitle1"
                            className={clsx(classes.customFontSize)}
                          >
                            Description:
                          </Typography>
                          <FormControl
                            className={clsx(classes.textField)}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                  borderColor: "#654DF7",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#654DF7",
                                },
                                "& fieldset": {
                                  borderColor: "transparent ",
                                },
                              },
                            }}
                          >
                            <OutlinedInput
                              id="outlined-adornment-name"
                              variant="outlined"
                              multiline
                              rows={1}
                              className={clsx(
                                classes.customFontSize,
                                classes.customBackgroung
                              )}
                              error={Error.description ? true : false}
                              placeholder="Enter description.."
                              value={description}
                              onChange={(e) => {
                                setDescription(e.target.value);
                                setError((prev) => ({
                                  ...prev,
                                  description: "",
                                }));
                              }}
                              InputProps={{
                                sx: {
                                  "&:hover fieldset": {
                                    borderColor: "#654DF7",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#654DF7 !important",
                                  },
                                },
                              }}
                            />
                          </FormControl>
                          {Error.description && (
                            <StyledTypography>
                              {Error.description}
                            </StyledTypography>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              </Grid>
            </Grid>
            {/* Left Section Part 2 */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card style={{ minHeight: "42vh" }}>
                  <Box className={classes.sideBar}>
                    <b>Run Settings</b>
                  </Box>

                  <div>
                    {/* Your existing content */}
                    <Grid container className={classes.body}>
                      <Grid container className={classes.body}>
                        {/* Row 1: Radio Buttons */}
                        {/* <Grid item>
                          <FormControl
                            component="fieldset"
                            className={classes.radioGroup}
                          >
                            <RadioGroup
                              aria-label="options"
                              name="options"
                              value={selectedSuiteValue}
                              onChange={handleRadioChange}
                              row
                            >
                              <FormControlLabel
                                value="custom-Suites"
                                control={<Radio style={{ color: "#654DF7" }} />}
                                label={
                                  <Typography
                                    variant="body1"
                                    className={classes.radioButtonLabel}
                                  >
                                    Custom Suites
                                  </Typography>
                                }
                                className={clsx(
                                  classes.radioLabel,
                                  classes.customFontSize
                                )}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid> */}

                        {/* Row 5: Environment Dropdown */}
                        <Grid item>
                          <div className={classes.input}>
                            <Typography
                              variant="subtitle1"
                              className={clsx(classes.customFontSize)}
                            >
                              Environment
                            </Typography>
                            <Select
                              getOptionLabel={(option) =>
                                option.EnvironmentName
                              }
                              getOptionValue={(option) => option.EnvironmentId}
                              isClearable={true}
                              options={environementList}
                              value={selectedEnvironment}
                              onChange={(newValue) => {
                                setSelectedEnvironment(newValue);
                                setError((prev) => ({
                                  ...prev,
                                  ["environment"]: "",
                                }));
                                // handleApplication(newValue);
                              }}
                              // styles={selectStyle}
                              styles={getSelectStyle(Error.environment)}
                              menuPosition={"fixed"} // Set menuPosition to fixed
                            />
                            {Error.environment && (
                              <StyledTypography>
                                {Error.environment}
                              </StyledTypography>
                            )}
                          </div>
                        </Grid>

                        {/* Row 3: Additional Name Dropdown */}
                        <Grid item>
                          <div>
                            <Typography
                              variant="subtitle1"
                              className={clsx(classes.customFontSize)}
                            >
                              Application :{" "}
                              {/* {selectedApplication
                                ? selectedApplication.ApplicationName
                                : ""} */}
                            </Typography>
                            <Select
                              getOptionLabel={(option) =>
                                option.ApplicationName
                              }
                              getOptionValue={(option) => option.ApplicationId}
                              isClearable={true}
                              options={applicationList}
                              value={selectedApplication}
                              onChange={(newValue) => {
                                handleApplicationChange(newValue);
                              }}
                              styles={getSelectStyle(Error.application)}
                              menuPosition={"fixed"} // Set menuPosition to fixed
                            />
                          </div>
                        </Grid>

                        {/* Row 6: Test User Dropdown */}
                        <Grid item>
                          <div className={classes.input}>
                            <Typography
                              variant="subtitle1"
                              className={clsx(classes.customFontSize)}
                            >
                              Test User
                            </Typography>
                            <Select
                              getOptionLabel={(option) => option.UserName}
                              getOptionValue={(option) => option.UserId}
                              isClearable={true}
                              options={testUserList}
                              value={selectedTestUser}
                              onChange={(newValue) => {
                                setSelectedTestUser(newValue);
                                setError((prev) => ({
                                  ...prev,
                                  ["test_user"]: "",
                                }));
                              }}
                              styles={getSelectStyle(Error.test_user)}
                              menuPosition={"fixed"} // Set menuPosition to fixed
                              sx={{ border: "2px solid pink" }}
                            />
                            {Error.test_user && (
                              <StyledTypography>
                                {Error.test_user}
                              </StyledTypography>
                            )}
                          </div>
                        </Grid>
                        {/* Row 4: Radio Buttons */}
                        <Grid
                          container
                          alignItems="center"
                          mb={0}
                          sx={{ display: "grid" }}
                        >
                          <Grid container spacing={2} sx={{ width: "100%" }}>
                            <Grid item>
                              <Typography className={classes.customFontSize}>
                                Email Recipient
                              </Typography>
                            </Grid>
                            <Box className={classes.togglebox}>
                              <Switch
                                checked={isSecondGridVisible}
                                onChange={handleToggleChange}
                                sx={{
                                  "& .MuiSwitch-thumb": {
                                    backgroundColor: isSecondGridVisible
                                      ? "#654DF7"
                                      : "#ffffff",
                                  },
                                  "& .MuiSwitch-track": {
                                    backgroundColor: isSecondGridVisible
                                      ? "#654DF7"
                                      : "#cccccc",
                                  },
                                }}
                              />{" "}
                            </Box>
                          </Grid>
                          {isSecondGridVisible && (
                            <Grid item>
                              <FormControl
                                component="fieldset"
                                className={classes.radioGroup}
                              >
                                <RadioGroup
                                  aria-label="options"
                                  name="options"
                                  value={selectedRecepentValue}
                                  onChange={handleRadioChangeRecepent}
                                  row
                                >
                                  <FormControlLabel
                                    value="only-for-me"
                                    control={
                                      <Radio
                                        style={{
                                          color: "#654DF7",
                                          padding: "0px 9px",
                                        }}
                                        classes={{
                                          checked: classes.checkedRadio, // Apply the custom checked class
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body1"
                                        className={classes.radioButtonLabel}
                                      >
                                        Only for me
                                      </Typography>
                                    }
                                    className={clsx(
                                      classes.radioLabel,
                                      classes.customFontSize
                                    )}
                                  />
                                  <FormControlLabel
                                    value="all-users"
                                    control={
                                      <Radio
                                        style={{
                                          color: "#654DF7",
                                          padding: "0px 9px",
                                        }}
                                        classes={{
                                          checked: classes.checkedRadio, // Apply the custom checked class
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body1"
                                        className={classes.radioButtonLabel}
                                      >
                                        All users
                                      </Typography>
                                    }
                                    className={classes.radioLabel}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          mb={1}
                          sx={{ display: "grid" }}
                        >
                          <Grid container spacing={2} sx={{ width: "100%" }}>
                            <Grid item>
                              <Typography className={classes.customFontSize}>
                                Execution mode
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <FormControl
                              component="fieldset"
                              className={classes.radioGroup}
                            >
                              <RadioGroup
                                aria-label="options"
                                name="options"
                                value={executionMode}
                                onChange={
                                  handleRadioChangeRecepent_executionmode
                                }
                                row
                              >
                                <FormControlLabel
                                  value="Sequential"
                                  control={
                                    <Radio
                                      style={{
                                        color: "#654DF7",
                                        padding: "0px 9px",
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant="body1"
                                      className={classes.radioButtonLabel}
                                    >
                                      Sequential
                                    </Typography>
                                  }
                                  className={clsx(
                                    classes.radioLabel,
                                    classes.customFontSize
                                  )}
                                />
                                <FormControlLabel
                                  value="Parallel"
                                  control={
                                    <Radio
                                      style={{
                                        color: "#654DF7",
                                        padding: "0px 9px",
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant="body1"
                                      className={classes.radioButtonLabel}
                                    >
                                      Parallel
                                    </Typography>
                                  }
                                  className={classes.radioLabel}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          {executionMode === "Parallel" && (
                            <>
                              <Typography
                                variant="subtitle1"
                                className={clsx(classes.customFontSize)}
                                sx={{ paddingLeft: '12px' }}
                              >
                                Parallel Threads
                              </Typography>
                              <div style={{ marginLeft: '12px' }}>
                              <Select
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                // isClearable={true}
                                options={numericOptions}
                                value={
                                  numericOptions.find(
                                    (option) => option.value === batchSize
                                  ) || null
                                }
                                onChange={(newValue) => {
                                  setbatchSize(
                                    newValue ? newValue.value : null
                                  );
                                }}
                                tyles={getSelectStyle(Error.batchSize)}
                                menuPosition={"fixed"}
                                placeholder={`Select No. of Parallel threads`}
                              />
                              </div>
                              {Error.batchSize && (
                                <StyledTypography sx={{ paddingLeft: '12px', marginTop: '8px' }}>
                                  {Error.batchSize}
                                </StyledTypography>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} sm={8}>
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <Grid
                    container
                    style={{
                      padding: "5px",
                      alignItems: "center", 
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item xs={6}>
                      <Box className={classes.sideBar} sx={{paddingLeft:'24px'}}>
                        <b>Test Cases</b>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      container
                      justifyContent="flex-end"
                    >
                      <SearchField
                        placeholder="Search Test Cases..."
                        onChange={(value) => setSearchTerm(value)}
                      />
                    </Grid>
                  </Grid>

                  {/* Table with some space */}
                  <div
                    style={{
                      overflow: "auto",
                      maxHeight: "calc(100vh - 223px)",
                      border: Error.testCaseError,
                    }}
                  >
                    <TestCaseTable
                      rows={testCases}
                      selectedRows={selectedRows}
                      // selectedRows={testCasesList.filter((test)=> suiteToEdit.SelectedTestCases.includes(test.TestCaseName))}
                      handleSelectAllChange={handleSelectAllChange}
                      handleCheckboxChange={handleCheckboxChange}
                      selectAll={selectAll}
                    />
                    <Box className={classes.testCaseErrorStyle}>
                      {Error.testCaseErrorText}
                    </Box>
                  </div>
                </Card>
              </Grid>
            </Grid>

            <Grid container style={{ paddingTop: "10px" }}>
              <Grid item xs={12}>
                <Box className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={
                      isSubmitting ||
                      executingSuite ||
                      filteredTestCaseData.length === 0
                    }
                    onClick={() => handleSubmit("Save")}
                    sx={{
                      backgroundColor: "rgb(101, 77, 247)",
                      "&:hover": {
                        backgroundColor: "rgb(101, 77, 247)",
                        borderColor: "#654DF7",
                      },
                    }}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={isSubmitting || filteredTestCaseData.length === 0}
                    onClick={() => handleSubmit("SaveAndExecute")}
                    sx={{
                      backgroundColor: "rgb(101, 77, 247)",
                      "&:hover": {
                        backgroundColor: "rgb(101, 77, 247)",
                        borderColor: "#654DF7",
                      },
                    }}
                  >
                    Save & Execute
                  </Button>

                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={handleCancel}
                    sx={{
                      backgroundColor: "rgb(108, 117, 125)",
                      color: "#f1f1f1",
                      "&:hover": {
                        backgroundColor: "rgb(101, 77, 247)",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
                {/* </Card> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Suspense>
  );
}
