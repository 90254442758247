import React, { useState } from 'react';
import Journey from './Journey';
import Dashboard from './Dashboard';

export default function AdvanceAutomation() {
  // State to manage the active tab
  const [activeTab, setActiveTab] = useState('journey');

  // Function to handle tab changes
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div style={{ display: 'flex', cursor: 'pointer', marginLeft:'12px', paddingTop:"10px" }}>
        <div
          onClick={() => handleTabChange('dashboard')}
          style={{
            padding: '10px',
            borderBottom: activeTab === 'dashboard' ? '2px solid blue' : 'none',
            fontWeight: activeTab === 'dashboard' ? 'bold' : 'normal'
          }}
        >
          Dashboard
        </div>
        <div
          onClick={() => handleTabChange('journey')}
          style={{
            padding: '10px',
            borderBottom: activeTab === 'journey' ? '2px solid blue' : 'none',
            fontWeight: activeTab === 'journey' ? 'bold' : 'normal'
          }}
        >
          Journey
        </div>
      </div>

      <div>
        {activeTab === 'dashboard' && <Dashboard />}
        {activeTab === 'journey' && <Journey />}
      </div>
    </div>
  );
}
